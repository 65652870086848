<template>
  <div>
    <div class="tw-border-b tw-border-gray-300">
      <div class="tw-flex tw-items-center tw-justify-between tw-px-6 tw-py-5">
        <h3 class="tw-text-2xl tw-capitalize tw-font-medium tw-inline-flex tw-items-center">
          {{ $tc('_trading', 2) }}
          <tooltip-select tip="_trading" class="tw-ml-2"></tooltip-select>
        </h3>
        <create-wallet :refresh="refresh" />
      </div>
    </div>
    <div class="tw-py-4 tw-px-3">
      <v-layout row wrap>
        <v-flex class="pa-1" xs10>
          <div class="text-xs-center pt-2">
            <pagination
              :query="q"
              :query-vars="vars"
              :page-pivot-fn="(item) => item.created"
              :total-fn="(data) => data.transactions.txns.length"
              :items-fn="(data) => data.transactions.txns"
              v-on:items="renderDataTable"
              v-on:isLoading="isPaginationLoadingHandler"
              ref="pagination"
            />
          </div>
        </v-flex>
        <v-flex class="pa-1" xs2>
          <div class="text-xs-right pt-2">
            <v-btn @click="refresh" color="success" style="margin-top: 15px"> Refresh </v-btn>
          </div>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex class="pa-1" xs12>
          <v-data-table
            :headers="headers"
            :items="transactions.txns"
            :loading="isLoadingAll ? 'accent' : false"
            v-model="selected"
            item-key="id"
            class="elevation-3"
            hide-actions
            disable-initial-sort
            select-all
          >
            <template slot="headers" slot-scope="tx">
              <tr>
                <th style="padding-top: 20px">
                  <v-checkbox @change="selectAll" v-model="sAll" />
                </th>
                <th v-for="header in tx.headers" :key="header.text">
                  {{ header.text }}
                </th>
                <th class="text-xs-right">
                  <v-btn
                    :disabled="selectedCount < 2"
                    :small="true"
                    color="secondary"
                    class="secondary"
                    @click="bulkAccept()"
                    v-if="checkScope(scopeLiterals.TransactionReconcileUpdate)"
                  >
                    {{ $t('_accept') }}
                  </v-btn>
                </th>
              </tr>
            </template>

            <template slot="items" slot-scope="tx">
              <tr :active="tx.selected">
                <td>
                  <v-checkbox primary hide-details v-model="tx.selected"></v-checkbox>
                </td>
                <td class="text-xs-left short-id">
                  <blockchain-explorer-link
                    :id="tx.item.id"
                    :amounts="tx.item.amounts"
                    :is-blockchain="tx.item.isBlockchain"
                  />
                </td>
                <td class="text-xs-left" style="white-space: nowrap">
                  {{ toPreferredDateTime(tx.item.created) }}
                </td>
                <td class="text-xs-left">
                  <div class="text-xs-left">
                    <ul style="list-style: none" class="pa-0">
                      <li v-for="item in tx.item.wallet" v-bind:key="item.name">
                        {{ item.name }}
                      </li>
                    </ul>
                  </div>
                </td>
                <td class="text-xs-left" style="color: green">
                  <div
                    v-for="(item, i) in tx.item.accountingDetails[0].trade.acquiredAssets"
                    :key="`${tx.item.id}-acq-${i}`"
                  >
                    <span style="white-space: pre">
                      {{ getCurrencySymbol(item.asset.coin) }}
                      {{ item.asset.displayValue }}
                    </span>
                  </div>
                </td>
                <td class="text-xs-left" style="color: red">
                  <div
                    v-for="(item, i) in tx.item.accountingDetails[0].trade.disposedAssets"
                    :key="`${tx.item.id}-disp-${i}`"
                  >
                    <span style="white-space: pre">
                      {{ getCurrencySymbol(item.asset.coin) }}
                      {{ item.asset.displayValue }}
                    </span>
                  </div>
                </td>
                <td class="text-xs-left" style="color: red" v-if="tx.item.accountingDetails[0].trade.tradeFee">
                  <div
                    v-for="(item, i) in tx.item.accountingDetails[0].trade.tradeFee.assets"
                    :key="`${tx.item.id}-fee-${i}`"
                  >
                    <span style="white-space: pre">
                      {{ getCurrencySymbol(item.asset.coin) }}
                      {{ item.asset.displayValue }}
                    </span>
                  </div>
                </td>
                <td class="text-xs-center">
                  <v-btn
                    @click="accept(tx)"
                    :small="true"
                    dark
                    class="secondary"
                    color="primary"
                    v-if="checkScope(scopeLiterals.TransactionReconcileUpdate)"
                    >{{ $t('_accept') }}</v-btn
                  >
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
      <save ref="txnSave" :refresh="refresh" />
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import moment from 'moment';

import Pagination from '../../components/Pagination';
import BlockchainExplorerLink from '../../components/transactions/BlockchainExplorerLink';
import Save from '../../components/transactions/Save.vue';
import { FilteredTransactionsQuery } from '../../queries/transactionsPageQuery';
import { MUT_SNACKBAR } from '../../store';

export default {
  data() {
    return {
      q: FilteredTransactionsQuery,
      transactions: { txns: [], count: 0 },
      isPaginationLoading: false,
      selected: [],
      sAll: false,
      headers: [
        // {},
        {
          text: this.$tc('_id'),
          align: 'left',
          value: 'id',
        },
        {
          text: this.$tc('_date'),
          align: 'left',
          value: 'created',
        },
        {
          text: this.$tc('_wallet', 1),
          align: 'left',
          value: 'wallet',
        },
        {
          text: this.$t('_tradeAcquired'),
          align: 'left',
          value: '',
        },
        {
          text: this.$tc('_tradeDisposed'),
          align: 'left',
          value: '',
        },
        {
          text: this.$tc('_fee'),
          align: 'left',
          value: '',
        },
      ],
    };
  },
  components: {
    Pagination,
    BlockchainExplorerLink,
    Save,
  },
  methods: {
    selectAll(value) {
      if (value) {
        this.selected = this.transactions.txns;
      } else {
        this.selected = [];
      }
    },
    accept(record) {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        id: record.item.id,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($orgId: ID!, $id: ID!) {
              acceptTrade(orgId: $orgId, id: $id)
            }
          `,
          variables: vars,
        })
        .then(() => {
          this.refresh();
        });
    },
    async bulkAccept() {
      try {
        const arr = this.selected.map((tx) => {
          const vars = {
            orgId: this.$store.state.currentOrg.id,
            id: tx.id,
          };
          return this.$apollo.mutate({
            mutation: gql`
              mutation ($orgId: ID!, $id: ID!) {
                acceptTrade(orgId: $orgId, id: $id)
              }
            `,
            variables: vars,
          });
        });
        await Promise.all(arr);
        this.$store.commit(MUT_SNACKBAR, {
          color: 'success',
          message: 'Bulk accept successfully',
        });
        this.refresh();
        this.sAll = false;
      } catch (e) {
        this.$store.commit(MUT_SNACKBAR, {
          color: 'error',
          message: 'Bulk accept error',
        });
        throw e;
      }
    },
    openSave(item) {
      this.$refs.txnSave.open(item.id);
    },
    renderDataTable(data) {
      this.transactions = data.transactions;
    },
    isPaginationLoadingHandler(isLoading) {
      this.isPaginationLoading = isLoading;
    },
    getCurrencySymbol(coin) {
      if (coin === 'BTC') {
        return '฿';
      } else if (coin === 'ETH') {
        return 'Ξ';
      } else if (coin === 'EOS') {
        return 'EOS';
      } else {
        return coin;
      }
    },
    refresh() {
      this.$refs.pagination.refetch();
    },
  },
  mounted() {
    this.refresh();
  },
  computed: {
    vars() {
      return {
        orgId: this.$store.state.currentOrg.id,
        transactionFilter: {
          categorizationFilter: 'Categorized',
          reconciliationFilter: 'Unreconciled',
          accountingDetails: {
            isTrade: true,
          },
        },
      };
    },
    isLoadingAll() {
      return this.isPaginationLoading;
    },
    selectedCount() {
      if (this.selected && this.selected.length) {
        return this.selected.length;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style scoped></style>
