


























import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { Category, Contact } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';

import SaveInline from './SaveInline.vue';

@Component({
  components: {
    SaveInline,
  },
})
/** Wraps the `SaveInline` component with a modal wrapper */
export default class Save extends BaseVue {
  @Prop()
  public readonly categories?: Category[];

  @Prop()
  public readonly contacts?: Contact[];

  @Prop({ required: true })
  public readonly refresh?: () => void;

  public txnId: string | null = null;
  public dialog = false;

  private get saveInlineComponent() {
    return this.$refs.saveInline as SaveInline | undefined;
  }

  public get btnTitle() {
    return this.saveInlineComponent?.btnTitle;
  }

  public open(txnId: string) {
    this.txnId = txnId;
    this.dialog = true;
  }

  public clear() {
    if (this.saveInlineComponent) {
      this.saveInlineComponent.description = '';
      this.saveInlineComponent.category = undefined;
      this.saveInlineComponent.contact = undefined;
    }
    this.dialog = false;
  }

  @Watch('dialog')
  public async onDialogChange() {
    if (this.saveInlineComponent) {
      await this.saveInlineComponent.refetch();
    }
  }
}
